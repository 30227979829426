var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"station-selector",class:_vm.darkMode ? 'dark' : 'light'},[_c('div',{staticClass:"stations"},[_c('div',{staticClass:"f5 mb-xs"},[_vm._v(_vm._s(_vm.$t('Select the number of stations')))]),_vm._v(" "),_c('div',{staticClass:"grid"},_vm._l((_vm.stationAmountsBasedOnPlayers),function(stationAmount){return _c('div',{key:stationAmount.amount,staticClass:"stationamount",on:{"click":function($event){return _vm.setNumberOfStations(stationAmount.amount)}}},[_c('UIButton',{staticClass:"white-button",class:{
            active:
              stationAmount.amount === _vm.booking.bookingStationTimes.length,
            'dark': _vm.darkMode
          },attrs:{"unstyled":true}},[_vm._v("\n          "+_vm._s(_vm.$tc('1 Station | {count} Stations', stationAmount.amount))+"\n        ")]),_vm._v(" "),(stationAmount.discount && !_vm.isOrganizationEducation)?_c('div',{staticClass:"discount f6"},[_vm._v("\n          "+_vm._s(stationAmount.discount.displayDiscount)+" "+_vm._s(_vm.$tc('discount', 1))+"\n        ")]):_vm._e()],1)}),0)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"f5 mb-xs"},[_c('span',[_vm._v(_vm._s(_vm.$t('Length of time')))]),_vm._v(" "),(!_vm.isOrganizationEducation)?_c('span',[_vm._v(_vm._s(_vm.$t('(Price Per Station)')))]):_vm._e()]),_vm._v(" "),(_vm.tiers)?_c('div',{staticClass:"grid"},_vm._l((_vm.experience.tiers),function(tier){return _c('UIButton',{key:tier.id,staticClass:"white-button",class:{
          active: tier.id === _vm.booking.bookingStationTimes[0].tier.id,
          'dark': _vm.darkMode
        },attrs:{"unstyled":true},on:{"click":function($event){return _vm.setTier(tier)}}},[_vm._v("\n        "+_vm._s(tier.length)+" "+_vm._s(_vm.$t('Min'))),(!_vm.isOrganizationEducation)?_c('span',[_vm._v("\n          - "+_vm._s(_vm.toLocationCurrency(tier.price)))]):_vm._e()])}),1):_c('Loading')],1),_vm._v(" "),_c('AnalyticsPixel',{attrs:{"url":_vm.analyticsPixelUrl}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }