<template>
    <div class="ma3 ml4 mr4">
      <h2 class="t-heading-2 mb4 text-center mt4">Select the game</h2>
      <div class="game-list grid grid2">
        <article class="game" v-for="game in games" :key="game.uuid" @click="selectGame(game)" :class="{
          active: game.uuid === selectedGameId
        }">
          <img :src="game.imageUrl" :alt="game.title"/>
          <div class="genres">
            <span class="f-label" :key="genre" v-for="genre in game.genres">{{ genre }}</span>
          </div>
          <div class="pa3 pl4 pr4">
            <h3 class="title text-center f2">{{ game.title }}</h3>
            <div class="details">
              <div>
                <p class="f-thead mb1">Difficulty</p>
                <p class="f-header">
                  <!-- <UIIcon
                    class="difficultyicon mr2 dib fill-geyser"
                    :name="`difficulty-easy`"
                    style="width: .75em"
                  ></UIIcon> -->
                  <span class="ttc dib">{{ difficultyLevelText(game.difficultyLevel) }}</span>
                </p>
              </div>
              <div>
                <p class="f-thead mb1">Age Rating</p>
                <p class="f-header">{{ ageRatingText(game.ageRating) }}</p>
              </div>
            </div>
          </div>
        </article>
      </div>
      <h2 class="t-heading-3 mb3 text-center mt4" v-if="durations.length">{{ $t('Select duration') }}</h2>
      <div class="duration-list grid grid2" v-if="durations.length">
        <UIButton
          v-for="duration in durations"
          :key="duration.minute"
          :ghost="true"
          class="white-button"
          @click="selectedDuration=duration"
          :class="{
            active: duration === selectedDuration
          }"
        >
          {{ $t('{minute} min - {price} €', duration) }}
        </UIButton>
      </div>
      <div class="bottom-bar">
        <UIButton :disabled="!selectedDuration || !selectedGameId || loading"  @click="checkout">{{ bookingButtonText }}</UIButton>
      </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from '@/core/shared/components/Loading';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';

@Component({
  props: {
    stationId: {
      type: String,
      required: true,
    },
  },
  components: {
    Loading,
    UIButton,
    UIIcon,
  },
})
export default class KioskBooking extends Vue {
  loading = false;
  durations = [];
  selectedGameId = null;
  selectedDuration = null;

  async created () {
    await this.$store.dispatch('getAvailableGames', { stationId: this.stationId });
  }

  get games () {
    return this.$store.state.kiosk.games.reverse();
  }

  selectGame (game) {
    this.selectedGameId = game.uuid;

    this.durations = Object.keys(game.durationPrices).map(key => {
      return {
        minute: key,
        price: game.durationPrices[key],
      };
    });
  }

  async checkout () {
    this.loading = true;
    const response = await this.$store.dispatch('makeReservation', {
      stationId: this.stationId,
      gameId: this.selectedGameId,
      duration: this.selectedDuration.minute,
    });

    const url = response?.data?.url;

    if (url) {
      return window.open(url);
    }

    this.$store.commit('setFlash', {
      type: 'error',
      message: this.$t('You experienced the error during the booking'),
    });
  }

  difficultyLevelText (difficultyLevel) {
    switch (difficultyLevel) {
    case 1: return 'Easy';
    case 2: return 'Medium';
    case 3: return 'Hard';
    default: return 'Easy';
    }
  }

  ageRatingText (ageRating) {
    if (ageRating === 0) {
      return 'None';
    }

    return `${ageRating}+`;
  }

  get bookingButtonText () {
    if (this.selectedGameId && this.selectedDuration) {
      return `Pay ${this.selectedDuration.price} € & Play`;
    }
    return 'Pay & Play';
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.text-center {
  text-align: center;
}

.game-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* gap: 20px; */
}

.game {
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 #0000,0 0 #0000,0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
  position: relative;
  border: 1px solid transparent;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(40, 59, 87, 0.2);
    opacity: 0;
    -webkit-transition: opacity .15s ease-in;
    transition: all .15s ease-in;
  }

  &.active {
    /* transform: scale(1.05); */
    border: 1px solid #283B57;
  }

  &.active::after {
    opacity: 1
  }

  & .title {
    /* @apply --f2; */
  }

  & .details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    margin-top: 1em;
  }

  & .genres {
    position: absolute;
    top: 0;
    display: flex;
    margin: 5px;
    width: 100%;
    span {
      background-color: #fff;
      border-radius: 5px;
      margin: 5px;
      padding: 5px 10px;
      font-weight: bold;
    }
  }

  & .pricing {
    display: flex;
    justify-content: center;
    gap: 3em;
    .price {
      border: 1px solid black;
      padding: 0.5em 1em;
      border-radius: 5px;
    }
  }
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .ui-button {
    width: 100%;
    border-radius: 0;
  }
}
</style>
