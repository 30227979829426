<template>
  <div class="ma3 ml4 mr4">
    <Panel class="loader mt5 tc">
      <Loading class="tc loading" v-show="loading" />
      <UIIcon v-if="statusIcon" :name="statusIcon.name" class="w3 mb4" :class="statusIcon.class"/>
      <h2 class="f3 tc mb5">{{this.statusText}}</h2>
      <div v-if="status === 'paid'">
        <ul>
          <li class="mb4"><img src="../assets/kiosk/grab_head_set.png"></li>
          <li><img src="../assets/kiosk/grab_controllers.png"></li>
        </ul>
      </div>
      <div v-if="status === 'failed'">
      </div>
    </Panel>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from '@/core/shared/components/Loading';
import Panel from '../../core/shared/components/Panel.vue';
import UIIcon from '../../core/shared/components/ui/UIIcon.vue';

@Component({
  props: {
  },
  components: {
    Loading,
    Panel,
    UIIcon,
  },
})
export default class KioskBooking extends Vue {
  loading = true;
  status = '';

  get sessionId () {
    return this.$route.query.session_id;
  }

  async created () {
    const response = await this.$store.dispatch('checkPaymentStatus', { sessionId: this.sessionId });
    this.loading = false;
    this.status = response?.data?.payment_status ? response?.data?.payment_status : 'waiting';
  }

  get statusText () {
    switch (this.status) {
    case 'paid':
      return 'Your payment was successful. Please wait while we start the game and review the instructions below.';
    case 'failed':
      return 'Your payment failed. If you were charged and still see this message, please ask the staff for assistance.';
    case 'waiting':
    default:
      return 'Please wait while we verify your payment status.';
    }
  }

  get statusIcon () {
    switch (this.status) {
    case 'paid':
      return {
        name: 'check-circle',
        class: 'fill-green',
      };
    case 'failed':
      return {
        name: 'error',
        class: 'fill-red',
      };
    case 'waiting':
      return {
        name: 'alert-triangle-fill',
        class: 'fill-error',
      };
    }
  }
}
</script>
