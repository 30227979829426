import rest from '@/core/restAPI';

export const getAvailableGames = async ({ commit }, { stationId }) => {
  const games = await rest.get(`public/station/${stationId}/available-games`).json();
  commit('setGames', { games });
};

export const makeReservation = async ({ commit }, { stationId, gameId, duration }) => {
  const response = await rest.post(`kiosk/checkout-link/${stationId}`, {
    json: {
      gameUUID: gameId,
      duration: duration,
    },
  }).json();

  return response;
};

export const checkPaymentStatus = async ({ commit }, { sessionId }) => {
  return rest.get(`kiosk/payment-status/${sessionId}`).json();
};
